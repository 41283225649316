import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import '../styles/Surveillance.css';
import logo from '../assets/logo.jpg';
// import iconImage from '../assets/Droneicon.png';

const Surveillance = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    setAnimationStarted(true);
  }, []);

  const services = [
    { name: 'Wild Life Surveillance',  path: '/surveillancedetail?type=wild', x: 300, y: 30, controlX: 400, controlY: 30, buttonXOffset: -140, buttonYOffset: -20 },
    { name: 'Flood Rescue',  path: '/surveillancedetail?type=flood', x: 180, y: 350, controlX: 180, controlY: 200, buttonXOffset: -70, buttonYOffset: 0 },
    { name: 'C&M Service',  path: '/surveillancedetail?type=cm', x: 600, y: 51, controlX: 600, controlY: 200, buttonXOffset: -70, buttonYOffset: -40 },
    { name: 'Security Drone Surveillance',  path: '/surveillancedetail?type=security', x: 500, y: 370, controlX: 400, controlY: 370, buttonXOffset: 0, buttonYOffset: -20 }
  ];

  const centerX = 400;
  const centerY = 200;

  return (
    <div className="surveillance-container">
      <div className="liness-container">
        <svg className="liness-svg" viewBox="0 0 800 400">
          {services.map((service, index) => {
            const path = `M ${centerX},${centerY} L ${service.controlX},${service.controlY} Q ${(service.controlX + service.x) / 2},${(service.controlY + service.y) / 2} ${service.x},${service.y}`;

            return (
              <g key={index}>
                <path
                  d={path}
                  className={`surveillance-path ${animationStarted ? 'animated' : ''}`}
                  style={{ transitionDelay: `${index * 0.1}s` }}
                />
                <foreignObject
                  x={service.x + service.buttonXOffset}
                  y={service.y + service.buttonYOffset}
                  width="140"
                  height="40"
                >
                  <Link to={service.path}  style={{ textDecoration: 'none', }}>
                  <button className={`surveillance-button ${animationStarted ? 'button-animated' : ''}`} style={{ transitionDelay: `${index * 0.1}s`, fontSize: "12px" }}>
                    {service.name}
                  </button>
                  </Link>
                </foreignObject>
              </g>
            );
          })}

          <g className="center-icon-group">
            {/* <image
              href={iconImage}
              x={centerX - 50}
              y={centerY - 50} 
              height="100" 
              width="100" 
              opacity="0.4" 
              className="center-icon"
            /> */}
            <image
              href={logo}
              x={centerX - 42}
              y={centerY - 42}
              height="60"
              width="60"
              style={{ width: "80px", height: "80px" }}
              z-index="-1"
              className="center-logo"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Surveillance;
