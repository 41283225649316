import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/Agriculturedetail.css';
import sprayingImage from '../assets/spraying.jpg';
import seedImage from '../assets/seed.jpg';
import cropImage from '../assets/crop.jpg';
import farmImage from '../assets/farm.jpg';

const AgricultureDetails = () => {
  const location = useLocation();

 useEffect(() => {
     const params = new URLSearchParams(location.search);
     const type = params.get('type');
 
     if (type) {
       const section = document.querySelector(`.${type}-agriculture`);
       if (section) {
         section.scrollIntoView({ behavior: 'smooth', block: 'center' });
       }
     }
   }, [location]);

  return (
    <div className="agriculture-details-container">
      <section className="spraying-agriculture">
        <img src={sprayingImage} alt="Agriculture Spraying" />
        <div className="content">
          <h2>Agriculture Spraying</h2>
          <p>HDS revolutionizes crop protection with pinpoint precision. Our drones deliver targeted pesticide application, minimizing
waste and environmental impact. This ensures even coverage across fields, protecting crops from pests and diseases for maximized yield and sustainability. HDS: Farming smarter, healthier harvests.
</p>
        </div>
      </section>

      <section className="seed-agriculture">
        <div className="content">
          <h2>Seed Blooming</h2>
          <p>HDS revolutionizes reforestation with intelligent drone seeding. Our high-precision drones transform barren landscapes into thriving ecosystems. By blanketing vast
areas with seed bombs, HDS accelerates reforestation efforts in challenging terrains.We don't stop at planting - integrated monitoring ensures optimal success rates, paving the way for a greener future</p>
        </div>
        <img src={seedImage} alt="Seed Blooming"  style={{borderRadius : "10px"}} />
      </section>

      <section className="crop-agriculture">
        <img src={cropImage} alt="Crop Counting" />
        <div className="content">
          <h2>Crop Counting</h2>
          <p>Leveraging high-resolution imagery and advanced AI algorithms, HDS delivers precise automated crop counts. Gain real time insights into your crop population, identify planting inconsistencies, and
optimize resource allocation for maximized yield. HDS empowers data-driven decisions for a smarter, more profitable farm.</p>
        </div>
      </section>

      <section className="farm-agriculture">
        <div className="content">
          <h2>Farm Monitoring</h2>
          <p>HDS cultivates smarter farms with the power of aerial intelligence. Our drone-based monitoring system provides a bird's-eye view of your entire operation, delivering real-time data at your fingertips. Identify crop health issues early, optimize irrigation based on
precise moisture levels, and track plant growth for maximized yield. HDS empowers
data-driven decision-making, ensuring a thriving future for your farm.</p>
        </div>
        <img src={farmImage} alt="Farm Monitoring" />
      </section>
    </div>
  );
};

export default AgricultureDetails;
