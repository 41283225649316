import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";  // useNavigate instead of useHistory
import logoImage from "../assets/logo.jpg";
import "../styles/Navbar.css";
import { FaShoppingCart } from "react-icons/fa";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const navigate = useNavigate();  // Use useNavigate for navigation

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  };

  const handleSearchItemClick = (path) => {
    setSearchTerm("");
    setShowSearchResults(false);
    navigate(path);  // Use navigate() for client-side navigation
  };

  const searchResults = [
    { name: "Cart", path: "/products" },
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "About", path: "/about" },
    { name: "Contact", path: "/contact" },
    { name: "School", path: "/education" },
    { name: "Inspection", path: "/inspection" },
    { name: "Survey", path: "/survey" },
    { name: "Surveillance", path: "/surveillance" },
    { name: "Agriculture", path: "/agriculture" },
    { name: "Inspection Detail", path: "/inspectiondetail" },
    { name: "Survey Detail", path: "/surveydetail" },
    { name: "Surveillance Detail", path: "/surveillancedetail" },
    { name: "Agriculture Detail", path: "/agriculturedetail" },
  ];

  return (
    <div className="wrapper">
      <div className="scrolling-text-container">
        <div className="scrolling-text">
          <a href="#">INER</a> | <a href="#">DRONE FLARE</a> | <a href="#">MICRO DRONE</a> | 
          <a href="#">HAWKING @ SWAVLAMBAN</a> | <a href="#">VINBAX</a> | <a href="#">VAJRA PRAHAR</a> | 
          <a href="#">ANTI DRONE SYSTEMS</a> | <a href="#">LONG ENDURANCE RPA</a> | 
          <a href="#">DRONE SWARMS</a> | <a href="#">TBI MOBILE SCANNER</a>
        </div>
      </div>

      {/* Navbar */}
      <nav className="navbar-container">
        <div className="logo-container">
          <img src={logoImage} alt="Company Logo" className="logo" />
          <span className="company-name">HDS</span>
        </div>

        {/* Search Bar */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          {showSearchResults && (
            <div className="search-dropdown">
              {searchResults
                .filter((result) =>
                  result.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((result, index) => (
                  <div
                    key={index}
                    className="search-item"
                    onClick={() => handleSearchItemClick(result.path)}
                  >
                    {result.name}
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Toggle Button for Mobile */}
        <button
          className="mobile-toggle"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? "✕" : "☰"}
        </button>

        {/* Navigation Links and Buttons */}
        <div className={`nav-content ${isMobileMenuOpen ? "open" : ""}`}>
          <ul className="nav-links">
            <li><Link to="/" onClick={() => setIsMobileMenuOpen(false)}>Home</Link></li>
            <li><Link to="/about" onClick={() => setIsMobileMenuOpen(false)}>About</Link></li>
            <li
              className="dropdown"
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <Link to="/services" className="dropdown-btn">
                Services ▾
              </Link>
              {dropdownOpen && (
                <ul className="dropdowns-menu" style={{ transition: 'none' }}>
                  <li><Link to="/inspection" onClick={() => setIsMobileMenuOpen(false)}>Inspection</Link></li>
                  <li><Link to="/surveillance" onClick={() => setIsMobileMenuOpen(false)}>Surveillance</Link></li>
                  <li><Link to="/survey" onClick={() => setIsMobileMenuOpen(false)}>Survey</Link></li>
                </ul>
              )}
            </li>
            <li><Link to="/agriculture" onClick={() => setIsMobileMenuOpen(false)}>Agriculture</Link></li>
            <li><Link to="/education" onClick={() => setIsMobileMenuOpen(false)}>School</Link></li>
            <li><Link to="/contact" onClick={() => setIsMobileMenuOpen(false)}>Contact</Link></li>
            <li><Link to="/contact" onClick={() => setIsMobileMenuOpen(false)}>
              Cart <FaShoppingCart className="cart-icon" style={{ marginLeft: "-1px", marginTop:"4%", marginBottom:"-4%",fontSize: "16px", color: "#000" }} />
            </Link></li>
          </ul>
          <div className="btn-group">
            <a href="/HDS.pdf" download="HDS-PROFILE.pdf" className="brochure-btn">
              Brochure
            </a>
            <Link to="/contact#contact-container" className="connect-btn" onClick={() => setIsMobileMenuOpen(false)}>
              Connect Us
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
