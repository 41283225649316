import React, { useState } from "react";
import "../styles/ContactPage.css";
import emailjs from "emailjs-com"; // Import EmailJS

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make sure to replace with your EmailJS details
    const serviceId = "service_4hwl04s";
    const templateId = "template_0rglwmp";
    const userId = "Ze4i7AStFVShwib6s";

    // Send email via EmailJS
    emailjs
      .send(serviceId, templateId, formData, userId)
      .then(
        (response) => {
          console.log("Success:", response);
          alert("Your query has been submitted successfully.");
          setFormData({ name: "", phone: "", email: "", message: "" });
        },
        (error) => {
          console.error("Failed to send email:", error);
          alert("Failed to submit the form. Please try again.");
        }
      );
  };

  return (
    <div className="contact-page">
      <section className="contact-info">
        <div className="contact-text">
          <h1 className="contact-title">
            Get in touch with <span>HDS</span>
          </h1>
          <p>
            We'd love to hear from you! Whether you have questions about our
            services, need a quote, or want to discuss a custom project, our
            team is here to help.
          </p>
    
        </div>
      </section>

      <div className="contact-container">
        <div className="contact-form">
          <div className="contact-head">
            <h1>
              Contact <span>Us</span>
            </h1>
            <p>
              HDS Where innovation takes flight. We offer comprehensive drone
              services, capturing high-resolution data for informed
              decision-making. Experience the future of aerial intelligence.
            </p>
          </div>

          <form className="forms" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>NAME</label>
              <span className="separator">:</span>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>PHONE</label>
              <span className="separator">:</span>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>EMAIL</label>
              <span className="separator">:</span>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>MESSAGE</label>
              <span className="separator">:</span>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
