import React, { useState , useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link ,useLocation} from "react-router-dom";
import Navbar from "./components/Navbar";
import MainPage from "./components/MainPage";
import About from "./components/About";
import Education from "./components/Education";
import ContactPage from "./components/ContactPage";
import ServicePage from "./components/ServicePage";
import Inspection from "./components/Inspection";
import Survey from "./components/Survey";
import Surveillance from "./components/Surveillance";
import Agriculture from "./components/Agriculture";
import Inspectiondetail from "./components/Inspectiondetail";
import Surveydetail from "./components/Surveydetail";
import Surveillancedetail from "./components/Surveillancedetail";
import Agriculturedetail from "./components/Agriculturedetail";

import logoImage from "./assets/logo.jpg";
import phoneImage from "./assets/phone.png";
import "./App.css";
import emailjs from "emailjs-com"; // Import EmailJS


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top whenever route changes
  }, [pathname]);

  return null;
}

function MobileIcon() {
  return (
    <div className="contacts-icon">
      <a href="tel:+91 99401 99407">
        <img src={phoneImage} alt="Call Us" />
      </a>
      <span className="contacts-number">9940199407</span>
    </div>
  );
}

const FooterSubscribe = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  // Handle email input change
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle subscription form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS credentials (replace with your actual values)
    const serviceId = "service_xvqfg96";
    const templateId = "template_bktwgsm";
    const userId = "Ze4i7AStFVShwib6s";

    // Send the subscription email to the admin
    emailjs
      .send(serviceId, templateId, { email }, userId)
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          setSubscribed(true); // Change button text to "Subscribed"
        },
        (error) => {
          console.error("Failed to send email:", error);
          alert("There was an error. Please try again.");
        }
      );
  };

  return (
    <div className="footer-subscribe">
      <div className="subscribe-text">
        <h2>Subscribe</h2>
        <p>Don't miss out on the opportunity to stay updated with the latest advancements.</p>
      </div>
      <div className="subscribe-input">
        <input
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={handleInputChange}
          required
        />
        <div className="butn">
          <button onClick={handleSubmit} type="submit" disabled={subscribed}>
            {subscribed ? 'Subscribed' : 'Subscribe'}
          </button>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensures scrolling to top on route change */}
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/products" element={<h2>Products Page</h2>} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/education" element={<Education />} />
          <Route path="/inspection" element={<Inspection />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/agriculture" element={<Agriculture />} />
          <Route path="/inspectiondetail" element={<Inspectiondetail />} />
          <Route path="/surveydetail" element={<Surveydetail />} />
          <Route path="/surveillancedetail" element={<Surveillancedetail />} />
          <Route path="/agriculturedetail" element={<Agriculturedetail />} />
        </Routes>
      </div>

      <MobileIcon /> {/* Mobile Icon Added Here */}

      <footer className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <img src={logoImage} alt="Company Logo" className="footer-logo" />
            <div>
              <h2 className="footer-title">HDS</h2>
              <p className="company-tagline">Your Vision Captured from Every Angle</p>
            </div>
          </div>

          <div className="footer-center">
            <h3>Contacts</h3>
            <p>+91 99401 99407</p>
            <p>businessdev@hawkingdefence.com</p>
            <p>www.hds-india.com</p>
          </div>

          <div className="footer-right">
            <h3>Address</h3>
            <p>Hawking Defence Services Pvt.Ltd </p>
            <p>No.45 JN Road, Thiruvallur </p>
            <p>Kamarajapuram,</p>
            <p>Tamil Nadu - 602001</p>
          </div>
        </div>

        <div className="footer-links-subscribe">
          <div className="footer-links-wrapper">
            <nav className="footer-nav">
              <ul>
                <h3>Quick Links</h3>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/home">School</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </nav>

            <nav className="footer-nav">
              <ul>
                <h3>Services</h3>
                <li><Link to="/inspection">Inspection</Link></li>
                <li><Link to="/survey">Survey</Link></li>
                <li><Link to="/surveillance">Surveillance</Link></li>
                <li><Link to="/agriculture">Agriculture</Link></li>
                <li><Link to="/home">Privacy Policy</Link></li>
              </ul>
            </nav>
          </div>

          {/* Footer Subscribe Component */}
          <FooterSubscribe />

          <div className="footer-social">
            <h3>Stay In Touch</h3>
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/hawking-defence-services-private-limited" className="social-icon" target="_blank" 
  rel="noopener noreferrer"><i className="fab fa-linkedin" ></i></a>
              <a href="https://x.com/Hawking_hds?t=DMlGHaUrnDMV3k00uIXRWQ&s=08 " className="social-icon" target="_blank" 
  rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="https://www.instagram.com/hawking_hds?igsh=MTJlbWQ1dDV5bXgwYw==4" className="social-icon" target="_blank" 
  rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              <a href="https://www.youtube.com/@HawkingDefenceServices" className="social-icon" target="_blank" 
  rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
            </div>
            <div className="help-icon">
              <a href="/contact" className="help-link"><i className="fas fa-question-circle"></i> Need Help?</a>
            </div>
          </div>
        </div>
      </footer>
    </Router>
  );
}

export default App;
