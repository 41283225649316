import React from "react";
import "../styles/About.css";
import droneImage from "../assets/ddd.jpg";
import expertImage from "../assets/expertise-icon.png";
import complianceImage from "../assets/compliance-icon.jpg";
import customImage from "../assets/custom-solutions-icon.jpg";
import sustainabilityImage from "../assets/sustainability-icon.jpeg";

const About = () => {
  return (
    <div className="about-container">
      {/* Title Section */}
      <h2 className="title">
        About <span className="highlight"> HDS</span>
      </h2>
      <p className="description">
        HDS is recognized as a leading drone service and sales provider,
        serving a wide range of industries with various applications. Our
        expert team, certified and experienced, utilizes state-of-the-art
        drone technology to deliver exceptional service. We offer a range
        of services such as aerial photography, videography, surveying,
        mapping, and inspections.
      </p>

      {/* Flex Layout for Image & Text */}
      <div className="content-container">
        <div className="image-container">
          <img src={droneImage} alt="Drone" className="drone-image" />
        </div>
        <div className="text-container">
          <div className="text-block">
            <h3 className="subtitle1">Our founders</h3>
            <p className="story-text1">
            Our founders recognized the untapped potential of drones in transforming how businesses and communities operate. With backgrounds in engineering, environmental science, and data analytics, they combined their expertise to create a company dedicated to pushing the boundaries of what’s possible. From initial research and development to field tests and pilot projects, HDS early days were marked by a relentless pursuit of excellence and innovation.
            </p>
          </div>
          <div className="text-block">
            <h3 className="subtitle2">Our story</h3>
            <p className="story-text2">
            HDS was born from a vision to revolutionize industries through the power of emerging drone technology. Founded by a team of passionate innovators and technology enthusiasts, our journey began with a simple yet profound mission: to leverage advanced aerial solutions for real-world challenges across various sectors.
            </p>
          </div>
        </div>
      </div>

      {/* Mission & Vision Section */}
      <div className="mission-vision-container">
        <div className="mission-content">
          <h2 className="mission-title">Our <span className="highlight">Mission</span></h2>
          <p className="mission-text"> <span className="highlight">HDS</span> is a leading provider of drone services and a pioneer in the
             development of indigenous drone technology. We are
             committed to delivering exceptional service through a
             comprehensive portfolio of aerial solutions, including
             photography, videography, surveying, mapping, and inspections.</p>
        </div>
        <div className="vision-content">
          <h2 className="vision-title">Our <span className="highlight">Vision</span></h2>
          <p className="vision-text"><span className="highlight">HDS</span> envisions a future where our skies are abuzz with "Made in India" drones,
            empowering every sector with this transformative technology. We strive to be at the
            forefront of drone innovation, developing high-quality, reliable drones accessible for
            diverse applications.</p>
        </div>
      </div>

      {/* Why Choose Olirum Section */}
      <div className="why-choose-container">
        <h2 className="why-title">Why choose <span className="highlight">HDS?</span></h2>
        <p className="why-description">
        HDS Where innovation takes flight. We offer comprehensive drone services, capturing high resolution data for informed decision-making. Experience the future of aerial intelligence
        </p>
        <div className="features-grid">
          <div className="feature">
            <img src={expertImage} alt="Expertise in Drone" className="feature-icon" />
            <h3>Expertise in Drone</h3>
            <p><span className="highlight">Hawking</span> brings extensive expertise in advanced drone technology, enabling precise and effective solutions tailored to diverse industry needs.</p>
          </div>
          <div className="features">
            <img src={complianceImage} alt="Safety and Compliance" className="feature-icon" />
            <h3>Safety and Compliance</h3>
            <p>With a steadfast commitment to safety and regulatory compliance, <span className="highlight">Hawking</span> ensures secure operations and peace of mind for all projects.</p>
          </div>
          <div className="feature1">
            <img src={customImage} alt="Customized Solutions" className="feature-icon1" />
            <h3>Customized Solutions</h3>
            <p><span className="highlight">Hawking</span> offers customized drone solutions designed to address specific challenges, delivering strategic insights and operational excellence.</p>
          </div>
          <div className="feature1">
            <img src={sustainabilityImage} alt="Sustainability Focus" className="feature-icon1" />
            <h3>Sustainability Focus</h3>
            <p>At <span className="highlight">Hawking</span>, innovation drives sustainable impact through pioneering drone applications that enhance environmental stewardship and operational efficiency.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;