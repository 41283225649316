import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/Surveillancedetail.css';
import wildImage from '../assets/wild.jpg';
import floodImage from '../assets/flood.jpg';
import cmImage from '../assets/cm.jpg';
import securityImage from '../assets/security.webp';

const SurveillanceDetails = () => {
  const location = useLocation();

 useEffect(() => {
     const params = new URLSearchParams(location.search);
     const type = params.get('type');
 
     if (type) {
       const section = document.querySelector(`.${type}-surveillance`);
       if (section) {
         section.scrollIntoView({ behavior: 'smooth', block: 'center' });
       }
     }
   }, [location]);

  return (
    <div className="surveillance-details-container">
      <section className="wild-surveillance">
        <img src={wildImage} alt="Wild Life Surveillance" />
        <div className="content">
          <h2>Wild Life Surveillance</h2>
          <p>HDS equips conservation with eagle eyes. Our
intelligent drones silently patrol vast landscapes,
monitoring wildlife populations with unparalleled
precision. Advanced sensors capture detailed
data, identifying species, tracking migration
patterns, and detecting threats like poaching.
Real-time analysis empowers park rangers to
make informed decisions, safeguarding
endangered animals for a thriving future.</p>
        </div>
      </section>

      <section className="flood-surveillance">
        <div className="content">
          <h2>Flood Rescue</h2>
          <p>HDS Soars Above Disaster: Our state-of-the art drones reach survivors in flooded areas
inaccessible by traditional means. Thermal
imaging locates victims even in low-light,
while lifesaving deliveries bring essential
supplies. HDS: Taking flight to save lives in
times of crisis.</p>
        </div>
        <img src={floodImage} alt="Flood Rescue" />
      </section>

      <section className="cm-surveillance">
        <img src={cmImage} alt="C&M Service" />
        <div className="content">
          <h2>C&M Service</h2>
          <p>HDS conquers cleaning and maintenance
challenges! Our high-powered drones expertly
handle wind turbine blades, industrial buildings,
solar panels, and corporate structures. Eliminate
the risks and inefficiencies of traditional
methods. HDS delivers a faster, safer, and cost effective solution, ensuring your assets stay
spotless and operating optimally.</p>
        </div>
      </section>

      <section className="security-surveillance">
        <div className="content">
          <h2>Security Drone Surveillance</h2>
          <p>HDS Drones: Guardians of the Sky. Our
advanced surveillance drones empower both
police and corporations. Law enforcement gains
a tactical advantage with real-time situational
awareness, while corporations enjoy cost effective perimeter control and remote asset
inspection. HDS: Soaring for a safer, more secure
future</p>
        </div>
        <img src={securityImage} alt="Security Drone Surveillance" />
      </section>
    </div>
  );
};

export default SurveillanceDetails;
