import React, { useEffect, useState, useRef } from 'react';

const Counter = ({ targetNumber, duration = 2000 }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const hasCounted = useRef(false); // To prevent repeat counting

  useEffect(() => {
    const startCounting = () => {
      let start = 0;
      const increment = targetNumber / (duration / 50);

      const counterInterval = setInterval(() => {
        start += increment;
        if (start >= targetNumber) {
          setCount(targetNumber);
          clearInterval(counterInterval);
        } else {
          setCount(Math.ceil(start));
        }
      }, 50);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !hasCounted.current) {
            startCounting();
            hasCounted.current = true; // Prevent counting again
            observer.unobserve(counterRef.current);
          }
        });
      },
      { threshold: 1.0 } // Fully visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [targetNumber, duration]);

  return <span ref={counterRef}>{count.toLocaleString()}+</span>;
};

export default Counter;
