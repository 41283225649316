import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Counter from '../components/Counter';
import { FaSearch } from "react-icons/fa";
import "../styles/MainPage.css";
import droneVideo from "../assets/drone.mp4";
import inspectionImage from "../assets/boiler.webp";
import surveyImage from "../assets/drone.jpg";
import surveillanceImage from "../assets/service-image.jpg";
import agricultureImage from "../assets/service-image.jpg";
import arrowImage from '../assets/image.png';
import icon1 from '../assets/icon-1.png';
import icon2 from '../assets/icon-2.png';
import icon3 from '../assets/icon-3.png';
import icon4 from '../assets/icon-4.png';



const MainPage = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const servicesRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null); // Use ref to store interval ID

  const services = [
    {
      id: 1,
      title: "Inspection",
      description: "Inspection is the use of unmanned aerial vehicles (UAVs) to inspect infrastructure, equipment, and hard-to-reach areas. It offers a safer, faster, and more cost-effective alternative to manual inspections. Drones are equipped with high-resolution cameras, thermal imaging, and LiDAR sensors to capture detailed data for industries like construction, energy, agriculture, and telecommunications.",
      image: inspectionImage,
      path: "/inspection",
      className: "boiler-inspection-card",
    },
    {
      id: 2,
      title: "Survey",
      description: "Survey and Mapping involve measuring, analyzing, and representing geographical features, land, or structures for planning, development, and decision-making. Surveying collects accurate data on elevations, boundaries, and locations, while mapping transforms this data into visual formats like maps or 3D models. Both are crucial in construction, urban planning, environmental studies, and navigation.",
      image: surveyImage,
      path: "/survey",
      className: "pipeline-inspection-card",
    },
    {
      id: 3,
      title: "Surveillance",
      description: "Surveillance is the continuous monitoring of activities, people, or places to ensure security, gather intelligence, or prevent threats. It is used in law enforcement, military, business security, and public safety. Modern surveillance relies on technologies like CCTV cameras, drones, biometrics, and AI-powered analytics to enhance real-time monitoring and data collection.",
      image: surveillanceImage,
      path: "/surveillance",
      className: "wind-turbine-inspection-card",
    },
    {
      id: 4,
      title: "Agriculture",
      description: "Agriculture is the practice of cultivating crops and raising livestock for food, fiber, and other products essential for human survival. It includes various methods like traditional farming, organic farming, and modern techniques such as precision agriculture, hydroponics, and vertical farming. Advanced technologies like drones, AI, and IoT are transforming agriculture by improving efficiency, crop yields, and sustainability.",
      image: agricultureImage,
      path: "/agriculture",
      className: "solar-panel-inspection-card",
    },
  ];

  // Start or restart the slider interval
  const startSliderInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear any existing interval
    }
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % services.length);
    }, 4000);
  };

  useEffect(() => {
    startSliderInterval(); // Start the interval on mount
    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [services.length]);

  // Navigate to previous slide
  const goToPrevSlide = () => {
    console.log("Previous button clicked"); // Debugging
    setCurrentSlide((prev) => (prev - 1 + services.length) % services.length);
    startSliderInterval(); // Restart the interval
  };

  // Navigate to next slide
  const goToNextSlide = () => {
    console.log("Next button clicked"); // Debugging
    setCurrentSlide((prev) => (prev + 1) % services.length);
    startSliderInterval(); // Restart the interval
  };

  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDiscoverMore = () => {
    navigate("/services");
  };

  return (
    <div className="main-page">
      <div className="content">
        <div className="chevron left">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <path className="chevron-path" d="M0 0 L100 50 L0 100" fill="none" strokeWidth="1" />
          </svg>
        </div>
        <h1>Above & Beyond</h1>
        <video autoPlay loop muted playsInline>
          <source src={droneVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p>Your Vision, Captured from every angle.</p>
        <div className="Arrow-container" onClick={scrollToServices}>
          <span className="Arrow"><img src={arrowImage} alt="arrow-image" /></span>
        </div>
        <div className="chevron right">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <path className="chevron-path" d="M100 0 L0 50 L100 100" fill="none" strokeWidth="1" />
          </svg>
        </div>
      </div>
      <div ref={servicesRef} className="services-container">
        <div className="service-card-slider">
          {services.map((service, index) => (
            <div
              key={service.id}
              className={`service-card ${index === currentSlide ? 'active' : 'hidden'}`}
              style={{ width: '400px' }}
            >
              <div className="service-image">
                <img src={service.image} alt={service.title} />
              </div>
              <div className="service-content">
                <h2 className="service-title">{service.title}</h2>
                <p className="services-description" style={{ fontSize: '14px', textAlign: 'left' }}>
                  {service.description}
                </p>
                <div className="service-footer">
                  <Link to="/services" className="details-button">More Details</Link>
                  <div className="icon-container">
                  <span className="icon" onClick={() => navigate("/services")} ><FaSearch style={{ fontSize: "16px", color: "white" }} /></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="slider-navigation">
          <button className="prev-btn" onClick={goToPrevSlide}>◀</button>
          <div className="dots">
            {services.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => setCurrentSlide(index)}
              ></span>
            ))}
          </div>
          <button className="next-btn" onClick={goToNextSlide}>▶</button>
        </div>
        <Link to="/services" className="discover-more-btn">Discover More</Link>
      </div>

      <div className="testament">
        <h2>Committed to Excellence in Drone Training</h2>
        <div className="testament-grid">
          <div className="testament-card">
            <img src={icon1} alt="Partners" className="testament-icon" />
            <h3 className="testament-number">
              <Counter targetNumber={400} duration={3000} />
            </h3>
            <p className="testament-text">Industry Partners</p>
          </div>
          <div className="testament-card">
            <img src={icon2} alt="Projects" className="testament-icon" />
            <h3 className="testament-number">
              <Counter targetNumber={500} duration={3000} />
            </h3>
            <p className="testament-text">Successful Projects</p>
          </div>
          <div className="testament-card">
            <img src={icon3} alt="Experience" className="testament-icon" />
            <h3 className="testament-number">
              <Counter targetNumber={84} duration={3000} />
            </h3>
            <p className="testament-text">Years of Experience</p>
          </div>
          <div className="testament-card">
            <img src={icon4} alt="Students" className="testament-icon" />
            <h3 className="testament-number">
              <Counter targetNumber={100000} duration={3000} />
            </h3>
            <p className="testament-text">Students Trained</p>
          </div>
        </div>
      </div>

      <div className="why-choose-us-container">
        <h2 className="why-choose-title">WHY CHOOSE US?</h2>
        <div className="why-choose-grid">
          <div className="main-feature">
            <p>HDS recognizes the critical nature of selecting the ideal drone service provider for your project's success. We distinguish
              ourselves through a comprehensive three-pronged approach.<br />
              By partnering with HDS you gain access to a team of experts, a
              comprehensive suite of services, and a commitment to staying
              ahead of the curve. Let us elevate your project and take your
              vision to new heights.</p>
          </div>
          <div className="secondary-features">
            <div className="feature-box feature-1">
              <p><span>Comprehensive Solutions:</span> We offer a holistic portfolio of
                drone services, encompassing aerial photography,
                videography, surveying, mapping, and inspections.
                Additionally, we provide top-of-the-line drone sales, with
                expert advisors to guide you towards the ideal solution that
                perfectly aligns with your specific needs.</p>
            </div>
            <div className="feature-box feature-2">
              <p><span>Unparalleled Expertise:</span> Our team prioritizes safety and
                quality in every operation. Our certified pilots and technical
                specialists possess the industry's leading knowledge.</p>
            </div>
            <div className="feature-box feature-3">
              <p><span>Dedication to Progress:</span> HDS remains at the forefront of the
                drone industry. We continuously integrate the latest
                advancements to deliver the most effective and innovative
                solutions available.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;