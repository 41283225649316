import React, { useState,useEffect } from "react";
import "../styles/Education.css";
import onFieldImage from "../assets/ddd.jpg";
import onFieldImage1 from "../assets/img1.jpg";
import onFieldImage2 from "../assets/img2.jpg";
import onFieldImage3 from "../assets/img3.jpg";
import onFieldImage4 from "../assets/img4.jpg";
import courseImg1 from "../assets/img1.jpg";
import courseImg2 from "../assets/img5.jpg";
import courseImg5 from "../assets/edu.jpeg";
import whyTrainImage from "../assets/service-image.jpg";
import whatSetsUsApartImage from "../assets/service-image.jpg";
import tailoredImage from "../assets/tailored.jpeg";
import pilotImage from "../assets/pilot.png";
import careerImage from "../assets/career.png";
import studentImage from "../assets/img2.jpg";

const mediaList = [onFieldImage1, onFieldImage2, onFieldImage3, onFieldImage4];

const testimonials = [
  {
    quote:
      " I've used several drone services before, but this one is by far the best! The quality of the aerial footage was stunning, and the team was super professional. Highly recommend for any project!",
    name: "John",
  },
  {
    quote:
      "Using drones for crop monitoring has revolutionized how we manage our farm. The ability to spot issues like pests or irrigation problems early has saved us both time and money. The data is incredibly detailed and easy to interpret.",
    name: "Ravi",
  },
  {
    quote:
      " The efficiency and accuracy of this drone service in mapping out field conditions are impressive. We've seen a significant improvement in yield and resource management since using their services. I highly recommend them for any agricultural needs.",
    name: "Naveen",
  },
  {
    quote:
      "We've integrated C&M drones into our construction projects, and they've been a game-changer. The ability to monitor site progress from above, track timelines, and spot potential issues before they become major problems has greatly improved our efficiency. The team is professional, and the data is accurate.",
    name: "Abdul",
  },
  {
    quote:
      "The C&M drone service has been incredibly helpful for our large-scale construction projects. The ability to monitor construction phases in real time and produce detailed reports has been invaluable for keeping everything on schedule and within budget. ",
    name: "Saravana",
  },
];

const faqs = [
  {
    question: "What is HDS’s DGCA-Certified Drone Pilot Training?",
    answer: "HDS offers DGCA-certified training programs for aspiring drone pilots, providing a blend of theoretical education and practical flying experience to ensure proficiency in drone operations.",
  },
  {
    question: "What are the available training courses?",
    answer: "We offer beginner, intermediate, and advanced drone pilot courses.",
  },
  {
    question: "What is the duration of the training courses?",
    answer: "Our training programs range from 1 week to 3 months depending on the course level.",
  },
  {
    question: "What certification do I receive upon completion of the course?",
    answer: "Upon successful completion, you will receive a DGCA-certified drone pilot license.",
  },
  {
    question: "How can I enroll in a training course?",
    answer: "You can enroll by visiting our website and filling out the registration form.",
  },
];




const courseData = [
  {
    title: "Course A | Small Class Duration - RPC Training",
    description:
      "Ideal for beginners, this course covers everything from basic drone operations to intermediate use.",
    duration: "8 Days",
    image: courseImg1,
  },
  {
    title: "Course B | Small Class Drone Training",
    description:
      "For tech-enthusiasts who need both small and medium drones, this course offers in-depth training in both theory and practice.",
    duration: "12 Days",
    image: courseImg2,
  },
  {
    title: "Course E | Remote Pilot Instructor (RPI)",
    description:
      "This course is not just for pilots but also for future instructors looking to teach upcoming batches of certified drone pilots.",
    duration: "6 Days",
    image: courseImg5,
  },
];

const Education = () => {
  const [startIndex, setStartIndex] = useState(0);
  const testimonialsPerPage = 3;

  const nextTestimonials = () => {
    setStartIndex((prevIndex) =>
      prevIndex + testimonialsPerPage >= testimonials.length ? 0 : prevIndex + testimonialsPerPage
    );
  };

  const prevTestimonials = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - testimonialsPerPage : prevIndex - testimonialsPerPage
    );
  };

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

// const Education = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === mediaList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mediaList.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change image every 3 seconds
  
    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return (
    <div className="schoolpage">
      {/* Hero Section */}
      <div className="hero-container">
        <div className="hero-text">
          <h1 className="hero-title">
            Embrace the Future.
            <br />
            <span className="hero-gradient">Be a Certified Drone Pilot</span>
          </h1>
          <div className="hero-info">
            <div className="hero-box">
              <span className="hero-titles">DGCA</span>
              <p className="hero-subtext">Approved</p>
            </div>
            <div className="hero-box">
              <span className="hero-titles">500+</span>
              <p className="hero-subtext">Pilots Trained</p>
            </div>
          </div>
          <button className="hero-button">Enroll Now</button>
        </div>

        <div className="hero-image">
          <img src={onFieldImage} alt="Drone Pilot Training" className="hero-img" />
        </div>
      </div>

      {/* On-Field Action Section */}
      <div className="on-field-container">
        <h2 className="section-title">On-Field Action</h2>
        <div className="slideshow-container">
          <img
            src={mediaList[currentIndex]}
            alt="On-Field Training"
            className="slideshow-img"
          />
          <button className="nav-button prev" onClick={prevSlide}>❮</button>
          <button className="nav-button next" onClick={nextSlide}>❯</button>
        </div>
      </div>

      {/* Tailored Courses Section */}
      <div className="courses-container">
        <h2 className="section-title">Tailored Courses for Every Aspirant</h2>
        <div className="courses-grid">
          {courseData.map((course, index) => (
            <div key={index} className="course-card">
              <img src={course.image} alt={course.title} className="course-img" />
              <div className="course-content">
                <h3 className="course-title">{course.title}</h3>
                <p className="course-description">{course.description}</p>
                <p className="course-duration">Duration: {course.duration}</p>
                <button className="course-button">Learn More</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="why-learn-container">
        <h2 className="section-title">Why Learn to Fly with HDS</h2>
        <div className="why-learn-content">
          <div className="why-box">
            <h3>Why Train With Us?</h3>
            <ul>
              <li>✔️ DGCA Certification</li>
              <li>✔️ Industry-Leading Instructors</li>
              <li>✔️ Comprehensive Support System</li>
              <li>✔️ Focus on Entrepreneurship</li>
            </ul>
            <button className="join-button">Join Today</button>
          </div>
          <img src={whyTrainImage} alt="Training Image" className="why-img" />
        </div>

        <div className="why-learn-content">
          <img src={whatSetsUsApartImage} alt="Expert Guidance" className="why-img" />
          <div className="why-box">
            <h3>What Sets Us Apart?</h3>
            <ul>
              <li>✔️ Expert Guidance</li>
              <li>✔️ Real-World Experience</li>
              <li>✔️ State-of-the-Art Labs</li>
              <li>✔️ Placement Support</li>
            </ul>
            <button className="join-button">Join Today</button>
          </div>
        </div>
      </div>
      <div className="testimonials-container">
        <h2 className="section-title">Success, Stories & Drones</h2>
        <div className="testimonials-slider">
          {testimonials
            .slice(startIndex, startIndex + testimonialsPerPage)
            .map((testimonial, index) => (
              <div key={index} className="testimonial-card">
                <p className="testimonial-quote">“{testimonial.quote}”</p>
                <p className="testimonial-name"><strong>{testimonial.name}</strong></p>
              </div>
            ))}
        </div>
        <div className="testimonials-controls">
          <button className="testimonial-arrow prev" onClick={prevTestimonials}>❮</button>
          <button className="testimonial-arrow next" onClick={nextTestimonials}>❯</button>
        </div>
      </div>
      <div className="career-opportunities-container">
  <h2 className="section-title">Career Opportunities After Training</h2>
  <div className="career-content">
    <div className="career-benefits">
      <div className="career-item">
        <img src={tailoredImage} alt="Placement Support" />
        <p>Tailored Job Placement Support</p>
      </div>
      <div className="career-item">
        <img src={pilotImage} alt="Drone Pilot" />
        <p>Exclusive Drone Pilot Opportunities</p>
      </div>
      <div className="career-item">
        <img src={careerImage} alt="Mentorship" />
        <p>Expert Mentorship Programs</p>
      </div>
    </div>
    <div className="career-image">
      <img src={studentImage} alt="Drone Training" />
    </div>
    </div>
      <button className="join-button">Join Today</button>
  </div>
  <div className="faq-container">
        <h2 className="section-title">FAQs</h2>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <strong>{faq.question}</strong>
              <span className="faq-arrow">{openIndex === index ? "▲" : "▼"}</span>
            </div>
            {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
    </div>


    </div>
  );
};

export default Education;
