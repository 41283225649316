import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/ServicePage.css";
import logoImage from "../assets/logo.jpg";
// import Sidebar from "./Sidebar";
import boilerInspection from "../assets/service-image.jpg"; // Import the image
import pipelineInspection from "../assets/service-image.jpg"; // Import the image
import windTurbineInspection from "../assets/service-image.jpg"; // Import the image
import solarPanelInspection from "../assets/service-image.jpg"; // Import the image

const ServicesPage = () => {
  const navigate = useNavigate();



  const handleMoreDetails = (path) => {
    navigate(path);
  };

  return (
    <div className="services-page">

      <h1 className="services-title" >Our Services </h1>

      <div className="services-container">
        <div className="service-card boiler-inspection-card">
          <div className="service-image">
            <img src={boilerInspection} alt="Boiler Inspection" />
          </div>
          <div className="service-content">
            <h2 className="service-title">Survey</h2>
            <p className="services-description">
            Survey and Mapping involve measuring, analyzing, and representing geographical features, land, or structures for planning, development, and decision-making. Surveying collects accurate data on elevations, boundaries, and locations, while mapping transforms this data into visual formats like maps or 3D models. Both are crucial in construction, urban planning, environmental studies, and navigation, using advanced tools like GPS, LiDAR, and drones for precision and efficiency.
            </p>
            <div className="service-footer">
              <button
                className="details-button"
                onClick={() => handleMoreDetails("/survey")}
              >
                More Details
              </button>
              {/* <div className="icon-container">
                <span className="icon">🔍</span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="service-card pipeline-inspection-card">
          <div className="service-image">
            <img src={pipelineInspection} alt="Pipeline Inspection" />
          </div>
          <div className="service-content">
            <h2 className="service-title">Inspection</h2>
            <p className="services-description">
            Inspection involves assessing land, structures, or infrastructure to gather accurate data for analysis and decision-making. It is widely used in construction, real estate, agriculture, and environmental studies. Modern survey inspections utilize technologies like drones, LiDAR, GPS, and 3D mapping to improve accuracy and efficiency. This method helps in detecting structural issues, monitoring progress, and ensuring compliance with safety and regulatory standards.
            </p>
            <div className="service-footer">
              <button
                className="details-button"
                onClick={() => handleMoreDetails("/inspection")}
              >
                More Details
                
              </button>
              {/* <div className="icon-container">
                <span className="icon">🔍</span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="service-card wind-turbine-inspection-card">
          <div className="service-image">
            <img src={windTurbineInspection} alt="Wind Turbine Inspection" />
          </div>
          <div className="service-content">
            <h2 className="service-title">Surveillance</h2>
            <p className="services-description">
            Surveillance is the continuous monitoring of activities, people, or places to ensure security, gather intelligence, or prevent threats. It is used in law enforcement, military, business security, and public safety. Modern surveillance relies on technologies like CCTV cameras, drones, biometrics, and AI-powered analytics to enhance real-time monitoring and data collection.
            </p>
            <div className="service-footer">
              <button
                className="details-button"
                onClick={() => handleMoreDetails("/surveillance")}
              >
                More Details
                
              </button>
              {/* <div className="icon-container">
                <span className="icon">🔍</span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="service-card solar-panel-inspection-card">
          <div className="service-image">
            <img src={solarPanelInspection} alt="Solar Panel Inspection" />
          </div>
          <div className="service-content">
            <h2 className="service-title">Agriculture</h2>
            <p className="services-description">
            Agriculture is the practice of cultivating crops and raising livestock for food, fiber, and other products essential for human survival. It includes various methods like traditional farming, organic farming, and modern techniques such as precision agriculture, hydroponics, and vertical farming. Advanced technologies like drones, AI, and IoT are transforming agriculture by improving efficiency, crop yields, and sustainability.
            </p>
            <div className="service-footer">
              <button
                className="details-button"
                onClick={() => handleMoreDetails("/agriculture")}
              >
                More Details
               
              </button>
              {/* <div className="icon-container">
                <span className="icon">🔍</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
