import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/Surveydetail.css';
import forestImage from '../assets/forest.jpg';
import roadImage from '../assets/road.webp';
import landImage from '../assets/land.jpeg';
import riverImage from '../assets/river.jpg';
import disasterImage from '../assets/disaster.png';
import miningImage from '../assets/mining.jpeg';
import smartImage from '../assets/smart.jpg';
import lidarImage from '../assets/lidar.jpg';

const SurveyDetails = () => {
  const location = useLocation();

   useEffect(() => {
       const params = new URLSearchParams(location.search);
       const type = params.get('type');
   
       if (type) {
         const section = document.querySelector(`.${type}-mapping`);
         if (section) {
           section.scrollIntoView({ behavior: 'smooth', block: 'center' });
         }
       }
     }, [location]);

  return (
    <div className="survey-details-container">
      <section className="forest-mapping">
        <img src={forestImage} alt="Forest Survey & Mapping" />
        <div className="content">
          <h2>Forest Survey & Mapping</h2>
          <p>HDS Company is on a mission towards a sustainable
future! By harnessing advanced drone technology,
we are transforming forest management and
wildlife conservation. Utilizing real-time data
enables informed decision-making, intricate 3D
models prioritize conservation efforts, and aerial
surveillance protects wildlife. HDS: Skyborne
firefighters creating geospatial databases for a
flourishing future.</p>
        </div>
      </section>

      <section className="road-mapping">
        <div className="content">
          <h2>Raod Mapping</h2>
          <p>HDS revolutionizes road construction with next generation drone mapping. Our drones capture
lightning-fast, ultra-precise data, keeping crews
safe in hazardous areas. Track progress in real-time,
avoid delays, and seamlessly collaborate with high resolution data. HDS: Paving the way for a smarter,
safer future in road construction.</p>
        </div>
        <img src={roadImage} alt="Raod Mapping" />
      </section>

      <section className="land-mapping">
        <img src={landImage} alt="Land Mapping" />
        <div className="content">
          <h2>Land Mapping</h2>
          <p>HDS Soars for Smarter Surveying: Our nationwide
drone services revolutionize land analysis. Capture
precise topographic maps and 3D models, even in
challenging areas. Eliminate risky ground surveys
and gain immediate results with HDS' advanced
data processing. We deliver clear visualizations for
informed decision-making.</p>
        </div>
      </section>

      <section className="river-mapping">
        <div className="content">
          <h2>River Mapping</h2>
          <p>Our drone technology provides cost effective solutions for local governments to
monitor the health of rivers. Easily collect
river photos, land use data, and up-to-date
water quality information – even in remote
locations. HDS offers high-resolution images
and intricate 2D/3D maps, offering a
comprehensive view for making informed
decisions in river management.</p>
        </div>
        <img src={riverImage} alt="Transmission Line Inspection" />
      </section>

      <section className="disaster-mapping">
        <img src={disasterImage} alt="Cooling Tower Inspection" />
        <div className="content">
          <h2>Disaster Management</h2>
          <p>Disasters strike fast, but HDS reacts faster.
Our intelligent drones deliver high resolution visuals and 3D models of disaster
zones in a single flight. AI processing
uncovers crucial details, while thermal
imaging aids rescues. Real-time data
empowers swift response, saving lives and
minimizing loss. HDS: Seeing disasters
clearly, for a faster recovery.</p>
        </div>
      </section>

      <section className="mining-mapping">
        <div className="content">
          <h2>Mining Survey</h2>
          <p>HDS blasts traditional mining methods into the
past. We offer a customized drone solution
tailored to your specific needs. Gain instant real time data for on-the-fly decision making,
optimize resource allocation with pinpoint
precision tracking, and craft detailed 3D mine
maps for maximized success. HDS delivers
accurate measurements, boosting production
and efficiency. We go beyond data - we're your
partner in achieving peak mining performance.
</p>
        </div>
        <img src={miningImage} alt="Mining Survey" />
      </section>

      <section className="smart-mapping">
        <img src={smartImage} alt="Smart City Survey" /> 
        <div className="content">
          <h2>Smart City Survey</h2>
          <p>HDS Takes Smart Cities to New Heights: Drones revolutionize urban planning with HDS. We capture detailed aerial data for informed infrastructure projects, like metro
             lines and bike paths. Generate insightful 3D models and high-resolution maps for analyzing social and environmental conditions. HDS also supports public safety with crowd control, disaster response, and real-time traffic monitoring.</p>
        </div>
        
      </section>

      <section className="lidar-mapping">
        <div className="content">
          <h2>Lidar Survey</h2>
          <p>HDS: Leading the LiDAR revolution in geospatial data. Our cutting-edge LiDAR drones create unmatched precision 3D models, transforming surveying across industries. Experts and advanced equipment
             guarantee accuracy in land development, disaster response, and more. HDS: Capturing the future of geospatial data, today.</p>
        </div>
        <img src={lidarImage} alt="Lidar Survey" />
      </section>
    </div>
  );
};

export default SurveyDetails;
