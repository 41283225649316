import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/Inspectiondetail.css';
import windTurbineImage from '../assets/wind.jpg';
import solarPanelImage from '../assets/solar.png';
import bridgeImage from '../assets/bridge.jpg';
import transmissionLineImage from '../assets/transmission.jpg';
import coolingTowerImage from '../assets/cooling.jpeg';
import boilerImage from '../assets/boiler.webp';
import pipelineImage from '../assets/pipeline.jpg';
import railwayImage from '../assets/railway.jpg';
import cellImage from '../assets/cell.jpg';
import chimneyImage from '../assets/chimney.jpg';

const InspectionDetails = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');

    if (type) {
      const section = document.querySelector(`.${type}-inspection`);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [location]);

  return (
    <div className="inspection-details-container">
      <section className="wind-turbine-inspection">
        <img src={windTurbineImage} alt="Wind Turbine Inspection" />
        <div className="content">
          <h2>Wind Turbine Inspection</h2>
          <p>HDS revolutionizes wind turbine inspections with advanced drones, high-resolution sensors, and expert analysis by Level 3 thermographers and NDT specialists. This efficient, cost-effective approach enhances safety, minimizes disruptions, and extends wind farm lifespan.</p>
        </div>
      </section>

      <section className="solar-panel-inspection">
        <div className="content">
          <h2>Solar Panel Inspection</h2>
          <p>HDS revolutionizes solar panel inspections with an industry-leading drone program, utilizing high-performance UAVs with advanced cameras and thermal sensors for unmatched data acquisition. Swift issue detection identifies cracks, delamination, and hotspots, enabling proactive maintenance that minimizes downtime and optimizes solar farm efficiency.</p>
        </div>
        <img src={solarPanelImage} alt="Solar Panel Inspection" />
      </section>

      <section className="bridge-inspection">
        <img src={bridgeImage} alt="Bridge Inspection" />
        <div className="content">
          <h2>Bridge Inspection</h2>
          <p>HDS revolutionizes bridge inspections with UAV technology that enhances worker safety by eliminating the need for risky climbing and scaffolding. Our high-resolution drones capture detailed data quickly, reducing traffic disruptions and minimizing downtime.The insights gathered support the development of targeted maintenance plans, ensuring the long-term safety and durability of bridges. With data-driven analysis, HDS empowers proactive and informed decision-making for effective bridge management.</p>
        </div>
      </section>

      <section className="transmission-line-inspection">
        <div className="content">
          <h2>Transmission Line Inspection</h2>
          <p>Enhance safety by eliminating risks with UAV-based inspections, ensuring unmatched efficiency and minimal operational disruption. Our cost-effective drone technology reduces inspection expenses while capturing high-resolution images for detailed power line analysis. Proactive issue detection improves grid reliability by preventing outages, and rapid drone deployment enables swift response to severe weather or accidents.</p>
        </div>
        <img src={transmissionLineImage} alt="Transmission Line Inspection" />
      </section>

      <section className="cooling-tower-inspection">
        <img src={coolingTowerImage} alt="Cooling Tower Inspection" />
        <div className="content">
          <h2>Cooling Tower Inspection</h2>
          <p>HDS ensures safety by eliminating confined space risks with Elios drones designed for navigating complex cooling tower interiors. Our advanced technology streamlines inspections, reducing time and costs compared to traditional methods. High-resolution visual and thermal data provide unparalleled insights, while 3D modeling transforms data into actionable intelligence for informed decision-making. Proactive maintenance helps detect issues early, preventing costly downtime and ensuring optimal cooling tower performance.</p>
        </div>
      </section>

      <section className="boiler-inspection">
        <div className="content">
          <h2>Boiler Inspection</h2>
          <p>HDS revolutionizes boiler safety by eliminating worker exposure to hazardous confined spaces with advanced drone inspections. Our Elios drones navigate tight areas with ease, ensuring unmatched accessibility while reducing inspection costs by up to 90% compared to traditional scaffolding methods. High-quality visuals and 3D mapping enhance decision-making, while our eco-friendly approach helps cut CO2 emissions by up to 649 metric tons annually. Experience the future of boiler inspections with HDS’s cutting-edge drone technology.</p>
        </div>
        <img src={boilerImage} alt="Boiler Inspection" />
      </section>

      <section className="pipeline-inspection">
        <img src={pipelineImage} alt="Railway Inspection" /> 
        <div className="content">
          <h2>Pipe Inspection</h2>
          <p>HDS enhances safety with BVLOS drone operations, keeping personnel away from hazardous environments during inspections. Our efficient deployments minimize downtime, reduce travel time, and prevent traffic disruptions. High-tech UAVs enable early and accurate leak detection, preventing costly repairs. Scalable and adaptable, our autonomous drones cover vast pipeline networks with ease. Automated workflows streamline data capture and analysis, empowering informed, data-driven decision-making.</p>
        </div>
        
      </section>

      <section className="railway-inspection">
        <div className="content">
          <h2>Railway Inspection</h2>
          <p>HDS's advanced drones overcome traditional railway inspection limitations by eliminating time delays, high costs, and schedule disruptions. They efficiently inspect vast infrastructure, including stations, platforms, tracks, bridges, and overhead lines, in a single operation. By replacing risky methods like scaffolding and rope access, drone inspections enhance worker safety while reducing costs and disruptions. Our swift deployments expedite inspections, improving overall efficiency in railway infrastructure management.</p>
        </div>
        <img src={railwayImage} alt="Railway Inspection" />
      </section>

      <section className="chimney-inspection">
        <img src={chimneyImage} alt="Railway Inspection" />
        <div className="content">
          <h2>Chimney Inspection</h2>
          <p>HDS's advanced drone solutions eliminate climbing risks, ensuring unmatched safety for both inspectors and chimneys. Our state-of-the-art drones conduct rapid inspections with minimal disruption, capturing high-resolution visuals for precise issue detection. Thermal imaging technology unveils hidden problems, providing a comprehensive chimney health analysis. Experience smarter, more efficient inspections with HDS for optimal performance and safety.</p>
        </div>   
      </section>

      <section className="celltower-inspection">
        <div className="content">
          <h2>Cell Tower Inspection</h2>
          <p>HDS revolutionizes cell tower inspections with advanced drone solutions, ensuring safety, efficiency, and in-depth analysis. By eliminating the risks of manned climbing, our drones conduct rapid assessments, reducing costs and enabling proactive maintenance. High-resolution imaging captures detailed data, covering hard-to-reach areas to identify issues like loose bolts, encroaching vegetation, pre-repair needs, and post-weather damage, ensuring the reliability of your communication infrastructure.</p>
        </div>
        <img src={cellImage} alt="Railway Inspection" />
        
      </section>
    </div>
  );
};

export default InspectionDetails;
