import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Import Link
import '../styles/Survey.css';
import logo from '../assets/logo.jpg';
// import iconImage from '../assets/Droneicon.png';

const Survey = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    setAnimationStarted(true);
  }, []);

  const services = [
    { name: 'Forest Survey & Mapping', path: '/surveydetail?type=forest', x: 300, y: 30, controlX: 350, controlY: 30, buttonXOffset: -140, buttonYOffset: -20 },
    { name: 'Road Mapping', path: '/surveydetail?type=road', x: 230, y: 110, controlX: 280, controlY: 110, buttonXOffset: -140, buttonYOffset: -20 },
    { name: 'Land Mapping', path: '/surveydetail?type=land', x: 225, y: 290, controlX: 280, controlY: 290, buttonXOffset: -140, buttonYOffset: -20 },
    { name: 'River Mapping', path: '/surveydetail?type=river', x: 310, y: 370, controlX: 360, controlY: 370, buttonXOffset: -140, buttonYOffset: -20 },
    { name: 'Disaster Management', path: '/surveydetail?type=disaster', x: 490, y: 30, controlX: 440, controlY: 30, buttonXOffset: 0, buttonYOffset: -20 },
    { name: 'Mining Survey', path: '/surveydetail?type=mining', x: 570, y: 110, controlX: 520, controlY: 110, buttonXOffset: 0, buttonYOffset: -20 },
    { name: 'Smart City Survey', path: '/surveydetail?type=smart', x: 580, y: 290, controlX: 520, controlY: 290, buttonXOffset: 0, buttonYOffset: -20 },
    { name: 'Lidar Survey', path: '/surveydetail?type=lidar', x: 500, y: 370, controlX: 450, controlY: 370, buttonXOffset: 0, buttonYOffset: -20 }
  ];

  const centerX = 400;
  const centerY = 200;

  return (
    <div className="survey-container">
      <div className="lines-container">
        <svg className="lines-svg" viewBox="0 0 800 400">
          {services.map((service, index) => {
            const path = `M ${centerX},${centerY} L ${service.controlX},${service.controlY} Q ${(service.controlX + service.x) / 2},${(service.controlY + service.y) / 2} ${service.x},${service.y}`;

            return (
              <g key={index}>
                <path
                  d={path}
                  className={`service-path ${animationStarted ? 'animated' : ''}`}
                  style={{ transitionDelay: `${index * 0.1}s` }}
                />
                <foreignObject
                  x={service.x + service.buttonXOffset}
                  y={service.y + service.buttonYOffset}
                  width="140"
                  height="40"
                >
                  <Link to={service.path} style={{textDecoration:'none'}}>
                  <button className={`survey-button ${animationStarted ? 'button-animated' : ''}`} style={{ transitionDelay: `${index * 0.1}s`, fontSize: "12px" }}>
                    {service.name}
                  </button>
                  </Link>
                </foreignObject>
              </g>
            );
          })}

          <g className="center-logo">
            {/* <image
              href={iconImage}
              x={centerX - 50}
              y={centerY - 50} 
              height="120" 
              width="120" 
              opacity="0.4" 
              className="center-icon"
            /> */}
            <image
              href={logo}
              x={centerX - 42}
              y={centerY - 42}
              height="50"
              width="50"
              style={{ width: "80px", height: "80px" }}
              z-index="-1"
              className="center-logo"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Survey;