import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Import Link
import '../styles/Inspection.css';
import logo from '../assets/logo.jpg';
// import iconImage from '../assets/Droneicon.png';

const Inspection = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    setAnimationStarted(true);
  }, []);

  const services = [
      { name: 'Wind Turbine Inspection', path: '/inspectiondetail?type=wind-turbine', x: 300, y: 30, controlX: 350, controlY: 30, buttonXOffset: -140, buttonYOffset: -20 },
      { name: 'Solar Panel Inspection', path: '/inspectiondetail?type=solar-panel', x: 230, y: 110, controlX: 280, controlY: 110, buttonXOffset: -140, buttonYOffset: -20 },
      { name: 'Bridge Inspection', path: '/inspectiondetail?type=bridge', x: 225, y: 200, controlX: 280, controlY: 200, buttonXOffset: -140, buttonYOffset: -20 },
      { name: 'Transmission Line Inspection', path: '/inspectiondetail?type=transmission-line', x: 225, y: 290, controlX: 280, controlY: 290, buttonXOffset: -140, buttonYOffset: -20 },
      { name: 'Cooling Tower Inspection', path: '/inspectiondetail?type=cooling-tower', x: 310, y: 370, controlX: 360, controlY: 370, buttonXOffset: -140, buttonYOffset: -20 },
      { name: 'Boiler Inspection', path: '/inspectiondetail?type=boiler', x: 490, y: 30, controlX: 440, controlY: 30, buttonXOffset: 0, buttonYOffset: -20 },
      { name: 'Pipeline Inspection', path: '/inspectiondetail?type=pipeline', x: 570, y: 110, controlX: 520, controlY: 110, buttonXOffset: 0, buttonYOffset: -20 },
      { name: 'Railway Inspection', path: '/inspectiondetail?type=railway', x: 575, y: 200, controlX: 520, controlY: 200, buttonXOffset: 0, buttonYOffset: -20 },
    { name: 'Chimney Inspection', path: '/inspectiondetail?type=chimney', x: 590, y: 290, controlX: 520, controlY: 290, buttonXOffset: 0, buttonYOffset: -20 },
    { name: 'Cell Tower Inspection', path: '/inspectiondetail?type=celltower', x: 500, y: 370, controlX: 450, controlY: 370, buttonXOffset: 0, buttonYOffset: -20 }
  ];

  const centerX = 400;
  const centerY = 200;

  return (
    <div className="inspection-container">
      <div className="ilines-container">
        <svg className="ilines-svg" viewBox="0 0 800 400">
          {services.map((service, index) => {
            const path = `M ${centerX},${centerY} L ${service.controlX},${service.controlY} Q ${(service.controlX + service.x) / 2},${(service.controlY + service.y) / 2} ${service.x},${service.y}`;

            return (
              <g key={index}>
                <path
                  d={path}
                  className={`iservice-path ${animationStarted ? 'animated' : ''}`}
                  style={{ transitionDelay: `${index * 0.1}s` }}
                />
                <foreignObject
                  x={service.x + service.buttonXOffset}
                  y={service.y + service.buttonYOffset}
                  width="140"
                  height="40"
                >
                  <Link to={service.path} style={{ textDecoration: 'none' }}>  {/* Added Link here */}
                    <button className={`inspection-button ${animationStarted ? 'button-animated' : ''}`} style={{ transitionDelay: `${index * 0.1}s`,fontSize: "12px"  }}>
                      {service.name}
                     </button>
                  </Link>
                </foreignObject>
              </g>
            );
          })}

          <g className="center-logo">
            {/* <image
              href={iconImage}
              x={centerX - 50}
              y={centerY - 50} 
              height="100" 
              width="100" 
              opacity="0.4" 
              className="center-icon"
            /> */}
            <image
              href={logo}
              x={centerX - 42}
              y={centerY - 42}
              height="60"
              width="60"
              style={{ width: "80px", height: "80px" }}
              z-index="-1"
              className="center-logo"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Inspection;
